/*dark-blue: 	#37474F*/
/* gray: 		#E0E0E0*/
/* yellow:		#FFD966*/

#root,
html,
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 15px;
}
a{
    text-decoration: none;
    color: inherit;
}

